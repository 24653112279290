import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DarkModeConfig } from 'src/app/models';
import { DarkModeService } from 'src/app/services/dark-mode.service';
import { Input } from '@angular/core';
import { NavBarCloseService } from 'src/app/services/nav-bar-close.service';
import { Constants } from 'src/app/shared';
import { NavOptionExtent } from 'src/app/services/nav-option-extent.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SetAreaService } from 'src/app/services/set-area.service';
import { ModalViewService } from 'src/app/services/modal-view.service';
import { SelectUseService } from 'src/app/services/select-use-info.service';
import { PrintService } from 'src/app/services/print.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, OnDestroy {
  /**
   * form group
   */
  form!: FormGroup;

  /**
   * Flag that valdiates if side menu should be visible.
   */
  sideMenuIsVisible!: boolean;
  /**
   * bbl Value
   */
  bbl: any = '';

  visualizeOpened: boolean = false;

  printReportOpened: boolean = false;

  enableVisualizePrintReport: boolean = false;

  /**
   * List of search options
   */
  searchOptions = [
    {
      name: 'Property Search',
      icon: 'icon-address_search',
      enabled: true,
      topSeparator: false,
      optionNumber: 0,
      percentage: '116px',
      clickable: true,
    },
    {
      name: 'Advanced Search',
      icon: 'icon-advanced_search',
      enabled: false,
      topSeparator: false,
      optionNumber: 1,
      percentage: '124px',
      clickable: false,
    },
    {
      name: 'Manage Layers',
      icon: 'icon-layers',
      enabled: false,
      topSeparator: false,
      optionNumber: 2,
      percentage: '110px',
      clickable: true,
    },
  ];

  /**
   * List of zoning options
   */
  zoningOptions = [
    {
      name: 'Zoning Data',
      identificatorName: '(D)',
      icon: 'icon-zoning_data',
      enabled: false,
      topSeparator: false,
      percentage: '118px',
      optionNumber: 3,
      clickable: true,
    },
    {
      name: 'Zoning Analysis',
      identificatorName: '(A)',
      icon: 'icon-zoning_analysis',
      enabled: false,
      topSeparator: false,
      percentage: '136px',
      optionNumber: 4,
      clickable: true,
    },
    {
      name: 'Urban Analytics',
      identificatorName: '(U)',
      icon: 'icon-urban-analytics',
      enabled: false,
      topSeparator: false,
      optionNumber: 5,
      percentage: '130px',
      clickable: false,
    },
  ];

  /**
   * List of zoning options
   */
  generalOptions = [
    {
      name: 'Visualize/Export',
      icon: 'icon-save',
      enabled: false,
      topSeparator: false,
      percentage: '120px',
      clickable: false,
      hasRedirection: false,
    },
    {
      name: 'Print Report',
      icon: 'icon-print',
      enabled: false,
      topSeparator: false,
      percentage: '94px',
      hasRedirection: false,
      clickable: false,
    },
    {
      name: 'Saved Items',
      icon: 'icon-folder',
      enabled: false,
      topSeparator: false,
      percentage: '94px',
      hasRedirection: false,
      clickable: false,
    },
    {
      name: 'Settings',
      icon: 'icon-settings',
      enabled: false,
      topSeparator: false,
      percentage: '72px',
      hasRedirection: false,
      clickable: false,
    },
    {
      name: 'Sign up / Log in',
      icon: 'icon-user',
      enabled: false,
      topSeparator: true,
      percentage: '116px',
      hasRedirection: true,
      clickable: false,
    },
  ];

  /**
   * flag that handles the theme mode.
   */
  themeMode!: any;

  /**
   * Subscription
   */
  subscription!: Subscription;

  /**
   * Flag that handles the dark mode configuration.
   */
  darkModeConfig!: DarkModeConfig;

  /**
   * Flag that indicates whe max number is activatd
   */
  maxNumberActive!: boolean;

  showExtentOptions!: boolean;

  enableVisualize!: boolean;

  assembleActive: boolean = false;

  /**
   * options list of radio split lot
   */
  radioExtentList: any[];

  existingEvelopedData: any;

  /**
   * Output that handles the option selected
   */
  @Output() handleOption = new EventEmitter<any>();
  /**
   * Input that handles the number of containers activated in a list.
   */
  @Input() counterContainerArray: any[] = [];
  /**
   * Input that is active when the first row has value
   */
  @Input() firstRowHasValue!: number;

  constructor(
    private darkmodeTheme: DarkModeService,
    private navBarCloseService: NavBarCloseService,
    private navOptionExtent: NavOptionExtent,
    private formBuilder: FormBuilder,
    private setAreaService: SetAreaService,
    private modalViewService: ModalViewService,
    private selectUseInfoService: SelectUseService,
    private printService: PrintService
  ) {
    this.radioExtentList = [
      {
        id: 'lot',
        nameRadio: 'radioExtent',
        formName: 'radioExtent',
        value: '1',
        label: 'Lot',
        disabled: true,
      },
      {
        id: 'block',
        nameRadio: 'radioExtent',
        formName: 'radioExtent',
        value: '2',
        label: 'Block',
        disabled: true,
      },
      {
        id: 'surrounding',
        nameRadio: 'radioExtent',
        formName: 'radioExtent',
        value: '3',
        label: 'Area',
        disabled: true,
      },
    ];
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      radioExtent: [],
    });
    this.themeMode = sessionStorage.getItem('theme');
    this.subscription = this.selectUseInfoService.selectUseData.subscribe(
      (data: any) => {
        this.bbl = sessionStorage.getItem('bbl');
        if (this.bbl) {
          this.generalOptions[1].enabled = false;
        }
      }
    );
    this.subscription = this.darkmodeTheme.darkModeData.subscribe(
      (data: DarkModeConfig) => {
        this.themeMode = data?.activeTheme;
      }
    );
    this.subscription = this.navOptionExtent.navBarData.subscribe(
      (data: boolean) => {
        this.generalOptions[0].clickable = data;
        this.generalOptions[1].clickable = data;
      }
    );
    this.subscription = this.navBarCloseService.navBarData.subscribe(
      (data: any) => {
        switch (data?.option) {
          case Constants.ZONING_ANALYSIS:
            this.zoningOptions[1].enabled = false;
            this.verifyPadding(this.counterContainerArray.length - 1);
            break;
          case Constants.ZONING_DATA:
            this.zoningOptions[0].enabled = false;
            this.verifyPadding(this.counterContainerArray.length - 1);

            break;

          case Constants.ADVANCED_SEARCH:
            this.searchOptions[1].enabled = false;
            this.verifyPadding(this.counterContainerArray.length - 1);

            break;

          case Constants.MANAGE_LAYERS:
            this.searchOptions[2].enabled = false;
            this.verifyPadding(this.counterContainerArray.length - 1);

            break;
          default:
            break;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Method that toggles the visibility of the side menu.
   */
  showSideMenu() {
    this.sideMenuIsVisible = !this.sideMenuIsVisible;
  }

  /**
   * Method that verifies the padding of the navbar according the numbers the max numbers active
   * @param arrayNumber number of the array
   */
  verifyPadding(arrayNumber: any) {
    if (arrayNumber === 0) {
      // this.setPaddingService.setPadding(0);
      this.maxNumberActive = false;
    } else if (arrayNumber === 1) {
      // this.setPaddingService.setPadding(0);
      this.maxNumberActive = false;
    } else if (arrayNumber === 2) {
      // this.setPaddingService.setPadding(2);
      this.maxNumberActive = true;
    }
  }

  /**
   * Method that handles the option type selected
   * @param index identifier value.
   */
  handleOptionSelected(arrayList: any[], index: number, optionNumber?: number) {
    const itemName = arrayList[index].name;
    const lastElement =
      this.counterContainerArray[this.counterContainerArray.length - 1];
    if (this.counterContainerArray.length < 2) {
      arrayList[index].enabled = !arrayList[index].enabled;
      this.handleOption.emit(itemName);
    } else if (optionNumber === 0) {
      arrayList[index].enabled = !arrayList[index].enabled;
      this.handleOption.emit(itemName);
    } else if (
      this.counterContainerArray[0] === optionNumber ||
      lastElement === optionNumber
    ) {
      arrayList[index].enabled = false;
      this.handleOption.emit(itemName);
    } else if (
      this.counterContainerArray.length === 2 &&
      this.firstRowHasValue !== optionNumber
    ) {
      this.searchOptions.forEach((element) => {
        if (element.enabled === true && element.optionNumber === optionNumber) {
          element.enabled = false;
        }
        if (element.optionNumber === lastElement) {
          element.enabled = false;
        }
      });
      this.zoningOptions.forEach((element) => {
        if (element.enabled === true && element.optionNumber === optionNumber) {
          element.enabled = false;
        }
        if (element.optionNumber === lastElement) {
          element.enabled = false;
        }
      });
      arrayList[index].enabled = !arrayList[index].enabled;
      this.handleOption.emit(itemName);
    } else if (this.counterContainerArray.includes(optionNumber)) {
      arrayList[index].enabled = false;
      this.handleOption.emit(itemName);
    } else if (optionNumber === 0) {
      arrayList[index].enabled = false;
      this.handleOption.emit(itemName);
    }
    if (
      this.counterContainerArray[0] === optionNumber &&
      this.maxNumberActive
    ) {
      this.verifyPadding(this.counterContainerArray.length - 1);
    } else if (lastElement === optionNumber) {
      this.verifyPadding(this.counterContainerArray.length - 1);
    } else {
      this.verifyPadding(this.counterContainerArray.length);
    }
  }

  validateIfExistEnvelopeData() {
    const bblNumber = sessionStorage.getItem('bbl');
    this.existingEvelopedData = sessionStorage.getItem(
      `za_envelope_${bblNumber}`
    );
  }

  handleGeneralOptionSelected(
    arrayList: any[],
    index: number,
    optionNumber?: number
  ) {
    this.generalOptions[index].enabled = !this.generalOptions[index].enabled;
    if (
      this.generalOptions[index].name === 'Visualize/Export' &&
      this.generalOptions[index].enabled === true
    ) {
      this.generalOptions[1].enabled = false;
      this.showExtentOptions = true;
      this.visualizeOpened = true;
      this.printReportOpened = false;
    } else if (
      this.generalOptions[index].name === 'Visualize/Export' &&
      this.generalOptions[index].enabled === false
    ) {
      this.close(index);
      this.visualizeOpened = false;
      this.printReportOpened = false;
    } else if (
      this.generalOptions[index].name === 'Print Report' &&
      this.generalOptions[index].enabled === true
    ) {
      this.validateIfExistEnvelopeData();
      this.generalOptions[0].enabled = false;
      this.showExtentOptions = true;
      this.visualizeOpened = false;
      this.printReportOpened = true;
      if (!!this.existingEvelopedData) {
        this.enableVisualizePrintReport = true;
      } else if (!this.existingEvelopedData) {
        this.enableVisualizePrintReport = false;
      }
    } else if (
      this.generalOptions[index].name === 'Print Report' &&
      this.generalOptions[index].enabled === false
    ) {
      this.close(index);
      sessionStorage.removeItem('printReportPdfFeature');
      this.visualizeOpened = false;
      this.printReportOpened = false;
    }
  }

  /**
   * Method that is triggered when the child element is clicked.
   * @param event event value
   */
  childClick(event: any) {
    event.stopPropagation();
  }

  close(index?: any) {
    this.showExtentOptions = false;
    if (index === 0) {
      this.setAreaService.handleSetArea(-1);
    }
    this.form = this.formBuilder.group({
      radioExtent: [],
    });
    this.enableVisualize = false;
    if (index === undefined) {
      for (let index = 0; index < 4; index++) {
        this.generalOptions[index].enabled = false;
      }
    } else {
      this.generalOptions[index].enabled = false;
    }
  }

  handlererRadio(event: any) {
    switch (event.value) {
      case '1':
        this.setAreaService.handleSetArea(0);

        break;

      case '2':
        this.setAreaService.handleSetArea(1);

        break;

      case '3':
        this.setAreaService.handleSetArea(2);

        break;

      default:
        break;
    }

    this.enableVisualize = true;
  }

  setVisualization(valueName: string) {
    if (!!this.enableVisualize || !!this.enableVisualizePrintReport) {
      this.modalViewService.handleOpenModalView(valueName);
    }
  }

  /**
   * Listeners that listen for especific click to close the expanded element
   * @param event listener event
   */
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (
      event.srcElement.className !== '' &&
      !!event.srcElement.className.includes('extent_option')
    ) {
      if (this.generalOptions[0].enabled) {
        this.close(0);
      } else {
        this.close(undefined);
      }
    }
  }
}
