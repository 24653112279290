export const environment = {
  testPanel: true,
  amazonUrl: 'https://api-test.zlvas.com/',
  mapbox: {
    accessToken:
      'pk.eyJ1IjoibWFuaWF0aWNjIiwiYSI6ImNsY2drbDF5bTNrYjYzcG1vbjVmN3oxMzUifQ.u_gZ4dcArz4CaxP0Dc6bZQ',
  },
  cognito: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_V0uUSEUTY',
    userPoolWebClientId: '6604gi72fpkdf2rhk8fpehvuub',
  },
};
